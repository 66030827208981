import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full',
  },
  {
    path: 'welcome',
    loadComponent: () => import('./welcome/welcome.page').then( m => m.WelcomePage)
  },
  {
    path: 'menu',
    loadComponent: () => import('./menu/menu.page').then( m => m.MenuPage),
    children: [
      {
        path: 'home',
        loadComponent: () => import('./home/home.page').then( m => m.HomePage)
      },
      {
        path: 'legal-disclaimer',
        loadComponent: () => import('./legal-disclaimer/legal-disclaimer.page').then( m => m.LegalDisclaimerPage)
      }
    ]
  },  {
    path: 'tutorial',
    loadComponent: () => import('./tutorial/tutorial.page').then( m => m.TutorialPage)
  },
  {
    path: 'tutorial-modal',
    loadComponent: () => import('./tutorial-modal/tutorial-modal.page').then( m => m.TutorialModalPage)
  },

];
